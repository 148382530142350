import React from "react"

import PropTypes from "prop-types"

const SectionText = ({ children, subHeading, heading, paragraph }) => {
  return (
    <section className="text">
      <h5>{subHeading}</h5>
      <h1>{heading}</h1>
      <p>{paragraph}</p>
      {children}
    </section>
  )
}

SectionText.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
}

export default SectionText

import React from "react"

import CardImage1 from "../assets/images/card-1.png"
import CardImage2 from "../assets/images/card-2.png"
import CardImage3 from "../assets/images/card-3.png"
import CardImage4 from "../assets/images/card-4.png"
import CardImage5 from "../assets/images/card-5.png"
import CardImage6 from "../assets/images/card-6.png"
import AboutCustom from "../components/AboutCustom"
import CardContainer from "../components/CardContainer"
import Client from "../components/Client"
import ContactUs from "../components/ContactUs"
import Footer from "../components/footer"
import Header from "../components/header"
import Hero from "../components/Hero"
import OurProcess from "../components/OurProcess"
import SEO from "../components/seo"
import TruckCard from "../components/TruckCard"
import WhyChoose from "../components/WhyChoose"

const IndexPage = () => {
  const trucks = [
    {
      desc: "The Premium Range Mudvac is our full rear opening door and tipping liquid waste solution.",
      image: CardImage1,
      name: "Mudvac",
      specs: [
        {
          desc: "10,000L w. full rear opening door and hydraulic tipping",
          point: "Waste Capacity",
        },
        { desc: "8mm Mild Steel", point: "Barrel" },
        {
          desc: "600CFM Pometravaini liquid ring – featuring tower technology",
          point: "Vacuum Pump",
        },
        {
          desc: "AS1210 CL3 and AS4343",
          point: "Compliance",
        },
        {
          desc: "1400L w. Comet HP Water Pump",
          point: "Fresh Water System",
        },
        {
          desc: "Volvo FE350hp i-shift auto transmission",
          point: "Cab Chassis",
        },
        {
          desc: "Fully rebuildable Molex valves",
          point: "Other",
        },
      ],
    },
    {
      desc: "The Premium Range Liquidvac is our maximised liquid waste unit with tipping function.",
      image: CardImage2,
      name: "Liquidvac",
      specs: [
        {
          desc: "11,000 w. hydraulic tipping function",
          point: "Waste Capacity",
        },
        { desc: "8mm Mild Steel", point: "Barrel" },
        {
          desc: "600CFM Pometravaini liquid ring – featuring tower technology",
          point: "Vacuum Pump",
        },
        {
          desc: "AS120 CL3 / AS4343",
          point: "Compliance",
        },
        {
          desc: "1400L w. Comet HP Water Pump",
          point: "Fresh Water System",
        },
        {
          desc: "Volvo FE350hp i-shift auto transmission",
          point: "Cab Chassis",
        },
        {
          desc: "Fully rebuildable Molex valves",
          point: "Other",
        },
      ],
    },
    {
      desc: "Fully self sufficient and compatible with any prime mover, the Premium Range Trivac trailer is our bulk liquid waste trailer solution.",
      image: CardImage3,
      name: "Trivac",
      specs: [
        {
          desc: "24,000L",
          point: "Waste Capacity",
        },
        { desc: "8mm Mild Steel", point: "Barrel" },
        {
          desc: "501CFM Fruitland vane pump driven by 3cyl diesel Hatz motor",
          point: "Vacuum Pump",
        },
        {
          desc: " AS1210 CL3 and AS4343",
          point: "Compliance",
        },
        {
          desc: " Fully rebuildable Molex valves",
          point: "Other",
        },
      ],
    },
    {
      desc: "Fully self sufficient and compatible with any prime mover, the Premium Range Tippervac trailer features a full rear opening door and tipping functionality.",
      image: CardImage4,
      name: "Tippervac",
      specs: [
        {
          desc: "22,000L w. full rear opening door and hydraulic tipping",
          point: "Waste Capacity",
        },
        { desc: "8mm Mild Steel", point: "Barrel" },
        {
          desc: "501CFM Fruitland vane pump driven by 3cyl diesel Hatz motor",
          point: "Vacuum Pump",
        },
        {
          desc: " AS1210 CL3 and AS4343",
          point: "Compliance",
        },
        {
          desc: " Fully rebuildable Molex valves",
          point: "Other",
        },
      ],
    },
    {
      desc: "Australia's only fully compliant, stainless steel portable vacuum unit. Featuring lift and tie down points and forklift compatible on all 4 sides.",
      image: CardImage5,
      name: "Evac Range",
      specs: [
        {
          desc: "800, 1600 and 3200L options available",
          point: "Waste Capacity",
        },
        { desc: "5mm Stainless Steel", point: "Barrel" },
        {
          desc: "70CFM Conde vane pump (800, 1600L) and 115CFM Conde on 3200L. Honda/ Hatz diesel drive options.",
          point: "Vacuum Pump",
        },
        {
          desc: "AS1210 CL3 and AS4343",
          point: "Compliance",
        },
        {
          desc: "300, 600, 1200L respectively with LP Honda pump",
          point: "Fresh Water System",
        },
        {
          desc: " Fully rebuildable Molex valves",
          point: "Other",
        },
      ],
    },
    {
      desc: "Our high quality custom builds typically feature all the same components as our Premium Range models. Get in touch for a quote on your custom truck, trailer or portable unit.",
      image: CardImage6,
      name: "Custom Builds",
      specs: [],
    },
  ]
  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault()
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        })
      })
    })
  }, [])
  return (
    <>
      <SEO title="Vacuum Truck Supplies" path="/" />
      <Header />
      <Hero />
      <WhyChoose />
      <AboutCustom />
      <CardContainer>
        {trucks.map((truck, i) => (
          <TruckCard
            key={i}
            image={truck.image}
            heading={truck.name}
            paragraph={truck.desc}
            specs={truck.specs}
          />
        ))}
      </CardContainer>
      <OurProcess />
      <Client />
      <ContactUs />
      <Footer />
    </>
  )
}

export default IndexPage

import React from "react"

import ContactUsImage from "../assets/images/contact-us.png"

const ContactUs = () => {
  return (
    <section
      className="contact-image"
      id="contact"
      style={{
        background: `url(${ContactUsImage}) center/cover no-repeat`,
      }}
    ></section>
  )
}

export default ContactUs

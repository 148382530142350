import React from "react"

import AboutCustomImage from "../assets/images/about-custom.png"
import SectionImage from "./SectionImage"
import SectionText from "./SectionText"

const AboutCustom = () => {
  return (
    <section className="about-custom" id="about-custom">
      <div className="about-custom__container">
        <div className="about-custom__image">
          <SectionImage src={AboutCustomImage} alt="About Custom Image" />
        </div>
        <SectionText
          subHeading="Custom Vacuum Units"
          heading="What About Custom?"
          paragraph=""
        >
          <p>
            For the longest time custom units were the only option for vacuum
            industry operators. By nature, that meant a build process featuring
            an intensive design period and extended build timeline.
          </p>
          <br />
          <p>
            While we still perform a small number of custom builds, we encourage
            all operators to consider the many benefits of selecting your next
            vacuum truck, trailer, or portable unit from our Ready to Earn
            Premium Range.
          </p>
          <br />
          <p>
            The design elements of each Premium Range unit have been informed by
            operator feedback from over 900 builds and result in a dramatically
            reduced delivery turnaround and maximised earning potential for our
            customers.
          </p>
        </SectionText>
      </div>
    </section>
  )
}

export default AboutCustom

import React, { useState } from "react"

import PropTypes from "prop-types"

import SpecsArrowIcon from "../assets/images/specs-arrow.png"

const TruckCard = ({ specs, image, heading, paragraph }) => {
  const [isOpen, setSpecs] = useState(false)

  const listOfClasses = ["truck-card__all-specs", "display-none"]

  const toggleSpecs = (e) => {
    setSpecs(!isOpen)
    if (isOpen) {
      e.target.firstChild.innerText = `View all specs`
      e.target.lastChild.className = ``
    } else {
      e.target.firstChild.innerText = `Close`
      e.target.lastChild.className = `rotate-180`
    }
  }

  if (isOpen) {
    listOfClasses.pop("display-none")
  }

  const allClass = listOfClasses.join(" ")

  return (
    <div className="truck-card">
      <div className="truck-card__image">
        <img src={image} alt={heading} />
      </div>
      <div className="truck-card__text">
        <div>
          <h2>{heading}</h2>
          <p>{paragraph}</p>
        </div>

        {specs.length === 0 ? (
          ""
        ) : (
          <>
            <div className={allClass}>
              <ul>
                {specs.map((spec, i) => (
                  <li key={i}>
                    <b>{spec.point}:</b> {spec.desc}
                  </li>
                ))}
              </ul>
            </div>

            <button onClick={toggleSpecs} className="btn btn__outline">
              <span>View all specs</span>
              <img src={SpecsArrowIcon} alt="Down Arrow Icon" />
            </button>
          </>
        )}
      </div>
    </div>
  )
}

TruckCard.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  specs: PropTypes.array.isRequired,
}

export default TruckCard

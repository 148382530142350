import React from "react"

import PropTypes from "prop-types"

import useLockBodyScroll from "../utils/lockScroll"

const SectionImage = ({ click, src, alt }) => {
  useLockBodyScroll()
  return (
    <div className="fullscreen">
      <div
        className="fullscreen__overlay"
        onClick={click}
        aria-hidden="true"
      ></div>
      <div className="fullscreen__image">
        <button onClick={click} className="btn btn__primary fullscreen__close">
          &times;
        </button>
        <img src={src} alt={alt} />
      </div>
    </div>
  )
}

SectionImage.propTypes = {
  alt: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
}

export default SectionImage

import React, { useState } from "react"

import PropTypes from "prop-types"

import FullscreenIcon from "../assets/images/fullscreen.png"
import FullScreenImage from "./FullScreenImage"

const SectionImage = ({ isHero, heroClick, src, alt }) => {
  const [showImage, setShowImageState] = useState(false)
  return (
    <>
      <div className="image__container">
        <img className="image" src={src} alt={alt} />
        <button
          onClick={isHero ? heroClick : () => setShowImageState(!showImage)}
          className="btn btn__primary"
        >
          <img src={FullscreenIcon} alt="Fullscreen Icon" />
        </button>
      </div>

      {showImage && !isHero ? (
        <FullScreenImage
          click={setShowImageState.bind(null, !showImage)}
          src={src}
          alt={alt}
        />
      ) : (
        ""
      )}
    </>
  )
}

SectionImage.propTypes = {
  alt: PropTypes.string.isRequired,
  heroClick: PropTypes.func,
  isHero: PropTypes.bool,
  src: PropTypes.string.isRequired,
}

export default SectionImage

import React from "react"

import CMVLogo from "../assets/images/cmv-logo.png"
import FruitLandLogo from "../assets/images/fruitland.png"
import PompetravainiLogo from "../assets/images/pompetravaini.png"
import VolvoLogo from "../assets/images/volvo.png"
import WallensteinLogo from "../assets/images/wallenstein.png"
import SectionText from "./SectionText"

const Client = () => {
  return (
    <section className="client">
      <div className="client__container">
        <SectionText
          subHeading="We only use quality Parts and suppliers"
          heading="Proud to work with"
          paragraph=""
        />
        <div className="client__images">
          <img src={CMVLogo} alt="cmv logo" />
          <img src={VolvoLogo} alt="Fruitland logo" />
          <img src={PompetravainiLogo} alt="Fruitland logo" />
          <img src={FruitLandLogo} alt="Fruitland logo" />
          <img src={WallensteinLogo} alt="Fruitland logo" />
        </div>
      </div>
    </section>
  )
}

export default Client

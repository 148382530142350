import React from "react"

import TickIcon from "../assets/images/tick.png"
import WhyChooseImage from "../assets/images/why-choose.png"
import SectionImage from "./SectionImage"
import SectionText from "./SectionText"

const WhyChoose = () => {
  return (
    <section className="why-choose" id="why-choose">
      <div className="why-choose__container">
        <SectionText
          subHeading="WHY VACuum Truck SUpplies?"
          heading="Why Choose VTS?"
          paragraph="Backed by 25 years of design and manufacturing experience, our Premium Range of trucks, trailers and portable units represents the cutting edge of performance and longevity. Combine that with an industry leading delivery turnaround and dedicated after sales support team and what you end up with is an unrivalled earning potential.
"
        >
          <h4 className="points-heading">
            The Premium Range design philosophy is simple:
          </h4>
          <div className="why-choose__points">
            <h4>
              <span className="tick-icon">
                <img src={TickIcon} alt="Tick Icon" />
              </span>
              Maximised waste capacity,
            </h4>
            <h4>
              <span className="tick-icon">
                <img src={TickIcon} alt="Tick Icon" />
              </span>
              High performing vacuum pump,
            </h4>
            <h4>
              <span className="tick-icon">
                <img src={TickIcon} alt="Tick Icon" />
              </span>
              Full AS1210 CL3/ AS4343 compliance,
            </h4>
            <h4>
              <span className="tick-icon">
                <img src={TickIcon} alt="Tick Icon" />
              </span>
              Only the highest quality components, and
            </h4>
            <h4>
              <span className="tick-icon">
                <img src={TickIcon} alt="Tick Icon" />
              </span>
              Simplified maintenance for reduced downtime
            </h4>
          </div>
        </SectionText>
        <div className="why-choose__image">
          <SectionImage src={WhyChooseImage} alt="Why Choose Image" />
        </div>
      </div>
    </section>
  )
}

export default WhyChoose

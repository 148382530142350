import React, { useState } from "react"

import Dropdown from "../components/Dropdown"
import Popup from "../components/Popup"
import Link from "../utils/link"

const options = [
  { label: "More Information", value: "information" },
  { label: "Truck/Trailer Quote", value: "truck-trailer" },
  { label: "Portable Unit Quote", value: "portable" },
  { label: "Custom Build Quote", value: "custom-buils" },
  { label: "Parts Enquiry", value: "parts-enquery" },
]

const QuoteForm = () => {
  const [showPopup, setPopupStatus] = useState(false)

  const [formStatus, setFormStatus] = useState({
    email: { hasError: true, value: null },
    firstName: { hasError: true, value: null },
    lastName: { hasError: true, value: null },
    postCode: { hasError: true, value: null },
  })

  const handleInput = (e) => {
    const newFormState = { ...formStatus }
    if (e.target.validity.valid) {
      e.target.className = ""
      newFormState[`${e.target.name}`].value = e.target.value
      newFormState[`${e.target.name}`].hasError = false
    } else {
      e.target.className = "input-error"
      newFormState[`${e.target.name}`].hasError = true
    }
    setFormStatus(newFormState)
  }

  const hasError =
    formStatus.email.hasError ||
    formStatus.firstName.hasError ||
    formStatus.lastName.hasError ||
    formStatus.postCode.hasError

  return (
    <>
      {showPopup ? <Popup click={setPopupStatus.bind(null, false)} /> : ""}
      <div className="get-quote">
        <h1>Get a free quote on your next vacuum truck</h1>
        <p>
          If you want more info on our Premium Range models or a price on a
          custom build, get in touch — we’d be happy to help.
        </p>
        <form
          className="get-quote__form"
          action="https://formspree.io/f/meqnaqav"
          method="POST"
        >
          <div className="form__row">
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name*"
              required
              onChange={handleInput}
            />
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name*"
              required
              onChange={handleInput}
            />
          </div>
          <div className="form__fluid">
            <input
              type="email"
              name="_replyto"
              id="email"
              placeholder="Email Address*"
              required
              onChange={handleInput}
            />
          </div>
          <div className="form__row">
            <input
              type="text"
              name="postCode"
              id="postCode"
              placeholder="Suburb/Postcode*"
              required
              onChange={handleInput}
            />
            <Dropdown options={options} placeholder="I’d like help with..." name="help_with" />
          </div>
          <div className="form__fluid">
            <button
              onClick={(e) => (hasError ? "" : setPopupStatus(!showPopup))}
              className="btn btn__secondary"
            >
              Request your free quote
            </button>
          </div>
          <Link to="https://www.dropbox.com/s/vmznpkbbbvuz8d8/VTS%20Premium%20Range%20Digital%20Full%20Catalogue%2022.pdf?dl=1">
            OR view our latest catalogue
          </Link>
        </form>
      </div>
    </>
  )
}

export default QuoteForm

import React, { useState } from "react"
import Slider from "react-slick"

import WhiteArrow from "../assets/images/arrow-white.png"
import SlideImage1 from "../assets/images/slideshow-1.png"
import SlideImage2 from "../assets/images/slideshow-2.png"
import SlideImage3 from "../assets/images/slideshow-3.png"
import SlideImage4 from "../assets/images/slideshow-4.png"
import SectionImage from "../components/SectionImage"
import FullScreenImage from "./FullScreenImage"
import QuoteForm from "./QuoteForm"
import SectionText from "./SectionText"

const Hero = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    customPaging: () => <div className="slider__link"></div>,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
  }

  const [image, setImageState] = useState(null)

  const [showHeroImage, setshowHeroImageState] = useState(false)

  const handleTruckCardClick = (data) => {
    const elementOffSet = document.querySelector(".card__container").offsetTop
    const portablesUnits = document.querySelectorAll(".truck-card")
    const portablesUnitsOffSet = portablesUnits[3].offsetTop

    if (data === "truck") {
      window.scrollTo({
        behavior: "smooth",
        top: elementOffSet - 200,
      })
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: elementOffSet + portablesUnitsOffSet - 190,
      })
    }
  }

  return (
    <section className="hero">
      <div className="hero__container">
        <SectionText
          subHeading="Australia’s leading vacuum manufacturer"
          heading="Ready to Earn"
          paragraph="After over 900 custom vacuum unit builds, we’ve designed the ultimate range of Ready to Earn vacuum trucks, trailers, and portable units. We call it the Premium Range.
"
        >
          <div className="hero__view-truck">
            <div
              onClick={() => {
                handleTruckCardClick("truck")
              }}
              className="view-truck__card"
              aria-hidden="true"
            >
              <h2>View trucks &amp; trailers</h2>
              <button className="btn btn__primary">
                <img src={WhiteArrow} alt="Arrow Right" />
              </button>
            </div>
            <div
              onClick={() => {
                handleTruckCardClick("portables")
              }}
              className="view-truck__card"
              aria-hidden="true"
            >
              <h2>View portable units</h2>
              <button className="btn btn__primary">
                <img src={WhiteArrow} alt="Arrow Right" />
              </button>
            </div>
          </div>
        </SectionText>
        <QuoteForm />
      </div>
      <div className="slider__container">
        <Slider {...settings}>
          <SectionImage
            isHero={true}
            heroClick={() => {
              setshowHeroImageState(!showHeroImage)
              setImageState(SlideImage1)
            }}
            src={SlideImage1}
            alt="Slide 1"
          />
          <SectionImage
            isHero={true}
            heroClick={() => {
              setshowHeroImageState(!showHeroImage)
              setImageState(SlideImage2)
            }}
            src={SlideImage2}
            alt="Slide 2"
          />
          <SectionImage
            isHero={true}
            heroClick={() => {
              setshowHeroImageState(!showHeroImage)
              setImageState(SlideImage3)
            }}
            src={SlideImage3}
            alt="Slide 3"
          />
          <SectionImage
            isHero={true}
            heroClick={() => {
              setshowHeroImageState(!showHeroImage)
              setImageState(SlideImage4)
            }}
            src={SlideImage4}
            alt="Slide 4"
          />
        </Slider>
      </div>
      {showHeroImage ? (
        <FullScreenImage
          click={setshowHeroImageState.bind(null, !showHeroImage)}
          src={image}
          alt="slide fullscreen image"
        />
      ) : (
        ""
      )}
    </section>
  )
}

export default Hero

import React, { useState } from "react"
import Slider from "react-slick"

import PropTypes from "prop-types"

import Link from "../utils/link"

const CardContainer = ({ children }) => {
  const [cardCount, setCardCount] = useState(1)
  const [isMobile, setIsMobile] = useState(false)

  React.useEffect(() => {
    document.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 640)
    })
  }, [])
  const settings = {
    afterChange: function (index) {
      setCardCount(index + 1)
    },
    autoplay: true,
    autoplaySpeed: 4000,
    customPaging: () => <div className="slider__link"></div>,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
  }

  return (
    <div className="card__container">
      {!isMobile ? children : <Slider {...settings}>{children}</Slider>}
      {!isMobile ? (
        ""
      ) : (
        <div className="mobile-view">
          <h4 className="card__count">{cardCount} / 6</h4>
          <Link to="/" className="btn btn__secondary">
            View our PDF Catalogue
          </Link>
        </div>
      )}
    </div>
  )
}

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardContainer

import React from "react"

import GraphDesktopImage from "../assets/images/graph-desktop.png"
import GraphIndexImage from "../assets/images/graph-index.png"
import GraphMobileImage from "../assets/images/graph-mobile.png"
import ProcessImage1 from "../assets/images/process-1.png"
import ProcessImage2 from "../assets/images/process-2.png"
import ProcessImage4 from "../assets/images/process-4.png"
import Volvo from "../assets/images/volvo.jpg"
import ProcessCard from "./ProcessCard"
import SectionImage from "./SectionImage"
import SectionText from "./SectionText"

const Process = () => {
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    document.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 640)
    })
  }, [])

  const GraphImage = !isMobile ? GraphDesktopImage : GraphMobileImage

  return (
    <div className="process" id="process">
      <div className="process__container">
        <div className="process__single-container">
          <SectionText
            subHeading="built to last"
            heading="Our Process"
            paragraph=""
          >
            <p>
              The right people, the right products, the right attitude, the
              right procedure, and the desire to produce the best we can.{" "}
              <b>There are no shortcuts to the end result.</b>
            </p>
            <ProcessCard
              count="01"
              heading="Attention to Detail is the VTS Way"
              paragraph="Ongoing continuous improvement has driven VTS from the start and that’s why we have grown to become Australia’s leading manufacturer of liquid waste trucks, trailers and portable units."
            />
          </SectionText>
          <div className="process__image">
            <SectionImage src={ProcessImage1} alt="Process Image" />
          </div>
        </div>
        <div className="process__single-container">
          <div className="process__image">
            <SectionImage src={ProcessImage2} alt="Process Image" />
          </div>
          <ProcessCard
            count="02"
            heading="Working Together"
            paragraph="At VTS our service doesn’t end at producing your vacuum unit. We care about the success of your operation, and that shines through in every detail of both the work we do and every engagement you have with us."
          />
        </div>
        <div className="process__graph">
          <SectionText
            subHeading="ready to earn"
            heading="VTS customers start earning sooner"
            paragraph=""
          />
          <div className="graph__image">
            <img src={GraphImage} alt="Graph" />
          </div>
          <img
            className="graph__index"
            src={GraphIndexImage}
            alt="Graph Index"
          />
        </div>
        <div className="process__single-container">
          <div className="process__image">
            <SectionImage src={Volvo} alt="Process Image" />
          </div>
          <ProcessCard
            count="03"
            heading="Strategic Partnerships"
            paragraph="For the trucks within our Premium Range we have chosen Volvo as the cab chassis. And to ensure VTS customers enjoy unrivaled access and support, we are connected directly with the international Volvo factory network and extensive Australian dealer network."
          />
        </div>
        <div className="process__single-container">
          <div className="process__image">
            <SectionImage src={ProcessImage4} alt="Process Image" />
          </div>
          <ProcessCard
            count="04"
            heading="Maintenance Efficiencies"
            paragraph="The purposeful design considerations of our Premium Range models make our units less prone to breakdowns and easier to maintain. In the unlikely event of a breakdown, we can also get you back on the road and earning quicker and easier. Ask us how!"
          />
        </div>
      </div>
    </div>
  )
}

export default Process

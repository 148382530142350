import React from "react"

import PropTypes from "prop-types"

import PopupImage from "../assets/images/popup.png"
import TickIcon from "../assets/images/tick.png"
import useLockBodyScroll from "../utils/lockScroll"

const Popup = ({ click }) => {
  useLockBodyScroll()

  return (
    <>
      <div
        className="overlay"
        onClick={click}
        onKeyDown={click}
        role="button"
        tabIndex={0}
      ></div>
      <div className="popup">
        <div className="popup__message">
          <div className="popup__image">
            <img src={PopupImage} alt="Popup" />
            <span className="tick">
              <img src={TickIcon} alt="Tick Icon" />
            </span>
          </div>
          <h6>Message Sent</h6>
          <h3>Thanks for your enquiry.</h3>
          <p>
            We’ve received your enquiry — thanks for getting in touch with
            Vacuum Truck Supplies. We aim to get back to all business requests
            within one business day. Hold tight — we’ll be in touch shortly.
          </p>
          <button onClick={click} className="btn btn__secondary">
            Close
          </button>
        </div>
      </div>
    </>
  )
}

Popup.propTypes = {
  click: PropTypes.func.isRequired,
}

export default Popup

import React from "react"

import PropTypes from "prop-types"

const ProcessCard = ({ count, heading, paragraph }) => {
  return (
    <div className="process-card">
      <div className="process-card__heading">
        <span className="count">{count}</span>
        <h4>{heading}</h4>
      </div>
      <p>{paragraph}</p>
    </div>
  )
}

ProcessCard.propTypes = {
  count: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
}

export default ProcessCard
